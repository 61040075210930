<template>
  <div
    :class="{ 'featured-products--loading': loading }"
    class="related-products"
  >
    <div class="related-products__list">
      <images-grid :images="products" />
    </div>

    <div class="related-products__load-more">
      <button
        :class="{ 'button--loading': loading }"
        class="button button--alt"
        @click="fetchFeatured(productsCount)"
      >
        Wczytaj więcej
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ImagesGrid from '../images-grid/images-grid.vue';

export default {
  name: 'RelatedProducts',
  components: {
    ImagesGrid,
  },
  props: {
    types: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters('product', ['products', 'productsCount', 'productsTotal']),
    buttonVisible() {
      return this.productsCount < this.productsTotal;
    },
    perPage() {
      if (this.productsCount === 0) {
        return 3;
      }
      return 12;
    },
  },
  created() {
    this.fetchFeatured();
  },
  methods: {
    async fetchFeatured(offset = 0) {
      this.loading = true;

      await this.$store.dispatch('product/fetchRelatedProducts', {
        offset,
        perPage: this.perPage,
        types: this.types,
      });

      this.loading = false;
    },
  },
};
</script>
